import { InputAdornment, Tooltip } from "@mui/material";
import RIcon from "common/misc/RIcon";
import { ITourismRevenueObject } from "commonlib";
import HTextField from "components/inputs/hookInputs/HTextField";
import WorksheetTextField from "components/tax-filings/sales-tax-revenue/WorksheetTextField";
import { useFormContext } from "react-hook-form";
import Utils from "services/Utils";
import { ITaxFilings } from "types/tax-filings";
import { parseDateFromYYYYMM } from "utils/dateUtils";
import { prettyPercentage } from "utils/priceUtils";
import AdjustmentWrapper from "../../tax-filings/sales-tax-revenue/AdjustmentWrapper";
import TourismTaxComparisonLine from "./TourismTaxComparisonLine";
import { ITourismTaxRevenueData } from "./TourismTaxRevenueModalV2";

export default function TourismTaxTable(props: {
  month: string;
  onTextFieldBlur?: () => void;
  isEditable?: boolean;
  calculatedTourismTaxRevenue?: ITourismRevenueObject;
  staticView?: boolean;
  salesRevenue?: ITourismTaxRevenueData["SalesRevenue"];
  objTaxFiling: ITaxFilings;
}) {
  let objForm = useFormContext();
  return (
    <table
      border={1}
      width={"100%"}
      style={{ borderCollapse: "collapse", textAlign: "center" }}
    >
      <thead>
        <tr className="revenueTableGrayField">
          <th style={{ width: props.staticView ? "150px" : "200px" }}>
            Tourism Revenue{" "}
            {!props.staticView && (
              <>
                <span>for Month</span> <br />
                <span style={{ color: "blue" }}>
                  {Utils.convertToMonthAndYearFormat(
                    parseDateFromYYYYMM(props.month || "")
                  )}
                </span>
              </>
            )}
          </th>
          <th>Gross</th>
          <th>Exempt</th>
          <th>Taxable Amount</th>
          <th>Allowance</th>
          {props.staticView ? (
            <>
              <th>%</th>
              <th>$</th>
            </>
          ) : (
            <th
              colSpan={props.staticView ? 1 : 2}
              style={{ minWidth: props.staticView ? "" : "200px" }}
            >
              <div>Tourism Tax Portion</div>
              <div
                className="flex-v-center"
                style={{
                  justifyContent: "space-between",
                  width: 110,
                  margin: "auto",
                }}
              >
                <span>%</span>
                <span>$</span>
              </div>
            </th>
          )}
          <th>Tax Collected</th>
          <th>Variance</th>
        </tr>
      </thead>
      <tbody style={{ fontWeight: "bold" }}>
        <tr style={{ fontWeight: "bold" }}>
          <td className="revenueTableGrayField">Transient Rentals</td>
          <td className="revenueInputField" style={{ color: "blue" }}>
            {props.staticView ? (
              <AdjustmentWrapper
                objTaxFiling={props.objTaxFiling}
                adjustmentKey="TOURISM_TRANSIENT_RENTALS_GROSS_REVENUE"
              >
                {Utils.prettyPrice(
                  props.calculatedTourismTaxRevenue
                    ?.TOURISM_TRANSIENT_RENTALS_GROSS_REVENUE
                )}
              </AdjustmentWrapper>
            ) : (
              <WorksheetTextField
                objTaxFiling={props.objTaxFiling}
                adjustmentKey={"TOURISM_TRANSIENT_RENTALS_GROSS_REVENUE"}
                enableSourceData
                name={`TOURISM_TRANSIENT_RENTALS_GROSS_REVENUE`}
                componentProps={{
                  // label: "Exempt Revenue",
                  // type: "number",
                  onBlur: () => {
                    if (props.onTextFieldBlur) {
                      props.onTextFieldBlur();
                    }
                  },

                  disabled: !props.isEditable,
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment:
                      Number(
                        props.calculatedTourismTaxRevenue
                          ?.TOURISM_TRANSIENT_RENTALS_GROSS_REVENUE
                      ) ||
                      !props.isEditable ||
                      !props.salesRevenue
                        ?.TRANSIENT_RENTALS_GROSS_REVENUE ? null : (
                        <InputAdornment position="end">
                          <Tooltip
                            title={`Fetch ${Utils.prettyPrice(
                              props.salesRevenue
                                ?.TRANSIENT_RENTALS_GROSS_REVENUE
                            )} From Sales tax`}
                          >
                            <span
                              style={{
                                cursor: "pointer",
                                marginBottom: "-5px",
                                marginRight: "-10px",
                              }}
                              onClick={() => {
                                objForm.setValue(
                                  "TOURISM_TRANSIENT_RENTALS_GROSS_REVENUE",
                                  props.salesRevenue
                                    ?.TRANSIENT_RENTALS_GROSS_REVENUE
                                );
                                if (props.onTextFieldBlur)
                                  props.onTextFieldBlur();
                              }}
                            >
                              <RIcon name="paid" />
                            </span>
                          </Tooltip>
                        </InputAdornment>
                      ),
                  },
                }}
                changeColor
                numberOnly={true}
              />
            )}
          </td>
          <td className="revenueInputField" style={{ color: "blue" }}>
            {props.staticView ? (
              <AdjustmentWrapper
                objTaxFiling={props.objTaxFiling}
                adjustmentKey="TOURISM_TRANSIENT_RENTALS_EXEMPT_REVENUE"
              >
                {Utils.prettyPrice(
                  props.calculatedTourismTaxRevenue
                    ?.TOURISM_TRANSIENT_RENTALS_EXEMPT_REVENUE
                )}
              </AdjustmentWrapper>
            ) : (
              <WorksheetTextField
                objTaxFiling={props.objTaxFiling}
                adjustmentKey={"TOURISM_TRANSIENT_RENTALS_EXEMPT_REVENUE"}
                name={`TOURISM_TRANSIENT_RENTALS_EXEMPT_REVENUE`}
                enableSourceData
                componentProps={{
                  // label: "Exempt Revenue",
                  // type: "number",
                  onBlur: () => {
                    if (props.onTextFieldBlur) {
                      props.onTextFieldBlur();
                    }
                  },

                  disabled: !props.isEditable,
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment:
                      Number(
                        props.calculatedTourismTaxRevenue
                          ?.TOURISM_TRANSIENT_RENTALS_EXEMPT_REVENUE
                      ) ||
                      !props.isEditable ||
                      !props.salesRevenue
                        ?.TRANSIENT_RENTALS_EXEMPT_REVENUE ? null : (
                        <InputAdornment position="end">
                          <Tooltip
                            title={`Fetch ${Utils.prettyPrice(
                              props.salesRevenue
                                ?.TRANSIENT_RENTALS_EXEMPT_REVENUE
                            )} From Sales tax`}
                          >
                            <span
                              style={{
                                cursor: "pointer",
                                marginBottom: "-5px",
                                marginRight: "-10px",
                              }}
                              onClick={() => {
                                objForm.setValue(
                                  "TOURISM_TRANSIENT_RENTALS_EXEMPT_REVENUE",
                                  props.salesRevenue
                                    ?.TRANSIENT_RENTALS_EXEMPT_REVENUE
                                );
                                if (props.onTextFieldBlur)
                                  props.onTextFieldBlur();
                              }}
                            >
                              <RIcon name="paid" />
                            </span>
                          </Tooltip>
                        </InputAdornment>
                      ),
                  },
                }}
                changeColor
                numberOnly={true}
              />
            )}
          </td>
          <td className="revenueStateCurrencyField">
            <AdjustmentWrapper
              objTaxFiling={props.objTaxFiling}
              adjustmentKey="_TOURISM_TRANSIENT_RENTALS_NET_REVENUE"
            >
              {Utils.prettyPrice(
                props.calculatedTourismTaxRevenue
                  ?._TOURISM_TRANSIENT_RENTALS_NET_REVENUE
              )}
            </AdjustmentWrapper>
          </td>
          <td className="revenueStateCurrencyField">
            <AdjustmentWrapper
              objTaxFiling={props.objTaxFiling}
              adjustmentKey="_TOURISM_TRANSIENT_RENTALS_ALLOWANCE"
            >
              {Utils.prettyPrice(
                props.calculatedTourismTaxRevenue
                  ?._TOURISM_TRANSIENT_RENTALS_ALLOWANCE
              )}
            </AdjustmentWrapper>
          </td>
          <td className="revenueNoneInputField revenuePercentageField">
            {prettyPercentage(
              props.calculatedTourismTaxRevenue?.TOURISM_TAX_PERCENTAGE || 0
            )}
          </td>
          <td className="revenueStateCurrencyField">
            <AdjustmentWrapper
              objTaxFiling={props.objTaxFiling}
              adjustmentKey="_TOURISM_TRANSIENT_RENTALS_DUE"
            >
              {Utils.prettyPrice(
                props.calculatedTourismTaxRevenue
                  ?._TOURISM_TRANSIENT_RENTALS_DUE
              )}
            </AdjustmentWrapper>
          </td>
          <td className="revenueInputField" style={{ color: "blue" }}>
            {props.staticView ? (
              <AdjustmentWrapper
                objTaxFiling={props.objTaxFiling}
                adjustmentKey="TOURISM_TRANSIENT_RENTALS_TAX_COLLECTED"
              >
                {Utils.prettyPrice(
                  props.calculatedTourismTaxRevenue
                    ?.TOURISM_TRANSIENT_RENTALS_TAX_COLLECTED
                )}
              </AdjustmentWrapper>
            ) : (
              <WorksheetTextField
                objTaxFiling={props.objTaxFiling}
                adjustmentKey={"TOURISM_TRANSIENT_RENTALS_TAX_COLLECTED"}
                name={`TOURISM_TRANSIENT_RENTALS_TAX_COLLECTED`}
                enableSourceData
                componentProps={{
                  onBlur: () => {
                    if (props.onTextFieldBlur) {
                      props.onTextFieldBlur();
                    }
                  },

                  disabled: !props.isEditable,
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  },
                }}
                changeColor
                numberOnly={true}
              />
            )}
          </td>
          <td className="revenueStateCurrencyField">
            <TourismTaxComparisonLine
              objTaxFiling={
                {
                  ...props.objTaxFiling,
                  Revenue: props.calculatedTourismTaxRevenue as any,
                } as any
              }
              Type="TRANSIENT_RENTAL"
            />
          </td>
        </tr>
        {!!props.calculatedTourismTaxRevenue?.IS_FOOD_AND_BEVERAGE_ENABLED && (
          <tr style={{ fontWeight: "bold" }}>
            <td className="revenueTableGrayField">Food And Beverage</td>
            <td className="revenueInputField" style={{ color: "blue" }}>
              {props.staticView ? (
                <AdjustmentWrapper
                  objTaxFiling={props.objTaxFiling}
                  adjustmentKey="TOURISM_FOOD_AND_BEVERAGE_GROSS_REVENUE"
                >
                  {Utils.prettyPrice(
                    props.calculatedTourismTaxRevenue
                      ?.TOURISM_FOOD_AND_BEVERAGE_GROSS_REVENUE
                  )}
                </AdjustmentWrapper>
              ) : (
                <WorksheetTextField
                  objTaxFiling={props.objTaxFiling}
                  adjustmentKey={"TOURISM_FOOD_AND_BEVERAGE_GROSS_REVENUE"}
                  enableSourceData
                  name={`TOURISM_FOOD_AND_BEVERAGE_GROSS_REVENUE`}
                  componentProps={{
                    // label: "Exempt Revenue",
                    // type: "number",
                    onBlur: () => {
                      if (props.onTextFieldBlur) {
                        props.onTextFieldBlur();
                      }
                    },

                    disabled: !props.isEditable,
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      endAdornment:
                        Number(
                          props.calculatedTourismTaxRevenue
                            ?.TOURISM_FOOD_AND_BEVERAGE_GROSS_REVENUE
                        ) ||
                        !props.isEditable ||
                        !props.salesRevenue
                          ?.FOOD_AND_BEVERAGE_GROSS_REVENUE ? null : (
                          <InputAdornment position="end">
                            <Tooltip
                              title={`Fetch ${Utils.prettyPrice(
                                props.salesRevenue
                                  ?.FOOD_AND_BEVERAGE_GROSS_REVENUE
                              )} From Sales tax`}
                            >
                              <span
                                style={{
                                  cursor: "pointer",
                                  marginBottom: "-5px",
                                  marginRight: "-10px",
                                }}
                                onClick={() => {
                                  objForm.setValue(
                                    "TOURISM_FOOD_AND_BEVERAGE_GROSS_REVENUE",
                                    props.salesRevenue
                                      ?.FOOD_AND_BEVERAGE_GROSS_REVENUE
                                  );
                                  if (props.onTextFieldBlur)
                                    props.onTextFieldBlur();
                                }}
                              >
                                <RIcon name="paid" />
                              </span>
                            </Tooltip>
                          </InputAdornment>
                        ),
                    },
                  }}
                  changeColor
                  numberOnly={true}
                />
              )}
            </td>
            <td className="revenueInputField">
              {props.staticView ? (
                <AdjustmentWrapper
                  objTaxFiling={props.objTaxFiling}
                  adjustmentKey="TOURISM_FOOD_AND_BEVERAGE_EXEMPT_REVENUE"
                >
                  {Utils.prettyPrice(
                    props.calculatedTourismTaxRevenue
                      ?.TOURISM_FOOD_AND_BEVERAGE_EXEMPT_REVENUE
                  )}
                </AdjustmentWrapper>
              ) : (
                <WorksheetTextField
                  objTaxFiling={props.objTaxFiling}
                  adjustmentKey={"TOURISM_FOOD_AND_BEVERAGE_EXEMPT_REVENUE"}
                  enableSourceData
                  name={`TOURISM_FOOD_AND_BEVERAGE_EXEMPT_REVENUE`}
                  componentProps={{
                    // label: "Exempt Revenue",
                    // type: "number",
                    onBlur: () => {
                      if (props.onTextFieldBlur) {
                        props.onTextFieldBlur();
                      }
                    },
                    disabled: !props.isEditable,
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      endAdornment:
                        Number(
                          props.calculatedTourismTaxRevenue
                            ?.TOURISM_FOOD_AND_BEVERAGE_EXEMPT_REVENUE
                        ) ||
                        !props.isEditable ||
                        !props.salesRevenue
                          ?.FOOD_AND_BEVERAGE_EXEMPT_REVENUE ? null : (
                          <InputAdornment position="end">
                            <Tooltip
                              title={`Fetch ${Utils.prettyPrice(
                                props.salesRevenue
                                  ?.FOOD_AND_BEVERAGE_EXEMPT_REVENUE
                              )} From Sales tax`}
                            >
                              <span
                                style={{
                                  cursor: "pointer",
                                  marginBottom: "-5px",
                                  marginRight: "-10px",
                                }}
                                onClick={() => {
                                  objForm.setValue(
                                    "TOURISM_FOOD_AND_BEVERAGE_EXEMPT_REVENUE",
                                    props.salesRevenue
                                      ?.FOOD_AND_BEVERAGE_EXEMPT_REVENUE
                                  );
                                  if (props.onTextFieldBlur)
                                    props.onTextFieldBlur();
                                }}
                              >
                                <RIcon name="paid" />
                              </span>
                            </Tooltip>
                          </InputAdornment>
                        ),
                    },
                  }}
                  changeColor
                  numberOnly={true}
                />
              )}
            </td>
            <td className="revenueStateCurrencyField">
              <AdjustmentWrapper
                objTaxFiling={props.objTaxFiling}
                adjustmentKey="_TOURISM_FOOD_AND_BEVERAGE_NET_REVENUE"
              >
                {Utils.prettyPrice(
                  props.calculatedTourismTaxRevenue
                    ?._TOURISM_FOOD_AND_BEVERAGE_NET_REVENUE
                )}
              </AdjustmentWrapper>
            </td>
            <td className="revenueStateCurrencyField">
              <AdjustmentWrapper
                objTaxFiling={props.objTaxFiling}
                adjustmentKey="_TOURISM_FOOD_AND_BEVERAGE_ALLOWANCE"
              >
                {Utils.prettyPrice(
                  props.calculatedTourismTaxRevenue
                    ?._TOURISM_FOOD_AND_BEVERAGE_ALLOWANCE
                )}
              </AdjustmentWrapper>
            </td>
            <td className="revenueNoneInputField revenuePercentageField">
              {prettyPercentage(
                props.calculatedTourismTaxRevenue
                  ?.TOURISM_TAX_FOOD_AND_BEVERAGE_PERCENTAGE
              )}
            </td>
            <td className="revenueStateCurrencyField">
              <AdjustmentWrapper
                objTaxFiling={props.objTaxFiling}
                adjustmentKey="_TOURISM_FOOD_AND_BEVERAGE_DUE"
              >
                {Utils.prettyPrice(
                  props.calculatedTourismTaxRevenue
                    ?._TOURISM_FOOD_AND_BEVERAGE_DUE
                )}
              </AdjustmentWrapper>
            </td>
            <td className="revenueInputField" style={{ color: "blue" }}>
              {props.staticView ? (
                <AdjustmentWrapper
                  objTaxFiling={props.objTaxFiling}
                  adjustmentKey="TOURISM_TRANSIENT_RENTALS_EXEMPT_REVENUE"
                >
                  {Utils.prettyPrice(
                    props.calculatedTourismTaxRevenue
                      ?.TOURISM_FOOD_AND_BEVERAGE_TAX_COLLECTED
                  )}
                </AdjustmentWrapper>
              ) : (
                <WorksheetTextField
                  objTaxFiling={props.objTaxFiling}
                  adjustmentKey={"TOURISM_FOOD_AND_BEVERAGE_TAX_COLLECTED"}
                  enableSourceData
                  name={`TOURISM_FOOD_AND_BEVERAGE_TAX_COLLECTED`}
                  componentProps={{
                    onBlur: () => {
                      if (props.onTextFieldBlur) {
                        props.onTextFieldBlur();
                      }
                    },

                    disabled: !props.isEditable,
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    },
                  }}
                  changeColor
                  numberOnly={true}
                />
              )}
            </td>
            <td className="revenueStateCurrencyField">
              <TourismTaxComparisonLine
                objTaxFiling={
                  {
                    ...props.objTaxFiling,
                    Revenue: props.calculatedTourismTaxRevenue as any,
                  } as any
                }
                Type="FOOD_AND_BEVERAGE"
              />
            </td>
          </tr>
        )}

        {!!props.calculatedTourismTaxRevenue?._TOURISM_AMOUNT_TO_BE_PAID &&
          !!props.calculatedTourismTaxRevenue?.TOURISM_TAX_CONVENIENCE_FEE && (
            <tr>
              <td colSpan={5}></td>
              <td>Convenience Fee</td>
              <td>
                <AdjustmentWrapper
                  objTaxFiling={props.objTaxFiling}
                  adjustmentKey="TOURISM_TAX_CONVENIENCE_FEE"
                >
                  {Utils.prettyPrice(
                    props.calculatedTourismTaxRevenue
                      ?.TOURISM_TAX_CONVENIENCE_FEE
                  )}
                </AdjustmentWrapper>
              </td>
            </tr>
          )}
        <tr className="revenueTotalHeadings">
          <td>Total</td>
          <td>{/* total gross */}</td>
          <td>{/* total exempt */}</td>
          <td>
            <AdjustmentWrapper
              objTaxFiling={props.objTaxFiling}
              adjustmentKey="_TOURISM_TOTAL_NET_REVENUE"
            >
              {Utils.prettyPrice(
                props.calculatedTourismTaxRevenue?._TOURISM_TOTAL_NET_REVENUE
              )}
            </AdjustmentWrapper>
          </td>
          <td>
            <AdjustmentWrapper
              objTaxFiling={props.objTaxFiling}
              adjustmentKey="_TOURISM_TOTAL_ALLOWANCE"
            >
              {Utils.prettyPrice(
                props.calculatedTourismTaxRevenue?._TOURISM_TOTAL_ALLOWANCE
              )}
            </AdjustmentWrapper>
          </td>
          <td>{/* percent */}</td>
          <td>
            <AdjustmentWrapper
              objTaxFiling={props.objTaxFiling}
              adjustmentKey="_TOURISM_AMOUNT_TO_BE_PAID"
            >
              {Utils.prettyPrice(
                props.calculatedTourismTaxRevenue?._TOURISM_AMOUNT_TO_BE_PAID
              )}
            </AdjustmentWrapper>
          </td>
          <td>
            {Utils.prettyPrice(
              (props.calculatedTourismTaxRevenue
                ?.TOURISM_TRANSIENT_RENTALS_TAX_COLLECTED || 0) +
                (props.calculatedTourismTaxRevenue
                  ?.TOURISM_FOOD_AND_BEVERAGE_TAX_COLLECTED || 0)
            )}
          </td>
          <td>
            <TourismTaxComparisonLine
              objTaxFiling={
                {
                  ...props.objTaxFiling,
                  Revenue: props.calculatedTourismTaxRevenue as any,
                } as any
              }
              Type="BOTH"
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
