import { Button, Popover, TextField, Tooltip } from "@mui/material";
import RIcon from "common/misc/RIcon";
import {
  CommonLibUtils,
  IWorksheetAdjustmentObject,
  IWorksheetAdjustmentSourceDataField,
} from "commonlib";
import useObjectState from "hooks/useObjectState";
import useRerender from "hooks/useRerender";
import { CommonUtils } from "hyper-utils";
import { useState } from "react";

interface IWorksheetSourceDataIconProps {
  objAdjustment: IWorksheetAdjustmentObject;
  onComplete?: (
    objAdjustment: IWorksheetAdjustmentObject,
    Total: number,
  ) => void;
  onClose?: () => void;
}

export default function WorksheetSourceDataIcon(
  props: IWorksheetSourceDataIconProps,
) {
  let [anchorEL, setAnchorEL] = useState<HTMLElement | null>(null);
  return (
    <>
      <RIcon
        name="edit"
        onClick={(e) => setAnchorEL(e.currentTarget)}
        className="cursor-pointer"
      />
      <Popover
        open={!!anchorEL}
        onClose={() => setAnchorEL(null)}
        anchorEl={anchorEL}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <SourceDataTable
          {...props}
          onComplete={(objAdjustment, total) => {
            setAnchorEL(null);
            props.onComplete?.(objAdjustment, total);
          }}
          onClose={() => setAnchorEL(null)}
        />
      </Popover>
    </>
  );
}

function SourceDataTable(props: IWorksheetSourceDataIconProps) {
  let { state, setState } = useObjectState<{
    fields: IWorksheetAdjustmentSourceDataField[];
    total: number;
  }>({
    fields: CreateInitialRows([
      ...(props?.objAdjustment?.SourceData?.Fields || ([] as any)),
    ]),
    total: 0,
  });

  function recalculateTotal() {
    let total = 0;
    for (let field of state.fields) {
      if (field.Operation === "+") {
        total += parseFloat(field.Amount as any);
      } else if (field.Operation === "-") {
        total -= parseFloat(field.Amount as any);
      } else if (field.Operation === "*") {
        total *= parseFloat(field.Amount as any);
      } else if (field.Operation === "/") {
        total /= parseFloat(field.Amount as any);
      }
    }
    setState({ total: CommonUtils.toFixedNumber(total, 2) });
  }

  const AddNewRow = () => {
    if (state.fields.length < 10) {
      state.fields.push({
        Label: "",
        Amount: 0,
        Operation: "",
      });
      setState({ fields: state.fields });
    }
  };

  return (
    <div
      style={{
        background: "white",
        color: "black",
        minHeight: 200,
        minWidth: 300,
        padding: 10,
        border: "2px solid #aaa",
      }}
    >
      <table className="w-full bordered-table">
        <thead>
        <tr>
          <th>Operation</th>
          <th>Description</th>
          <th>Amount</th>
        </tr>
        </thead>
        <tbody>
        {state.fields.map((field, index) => (
          <tr key={index}>
            <td>
              {index !== 0 && (
                <div>
                  <select
                    value={field.Operation}
                    onChange={(e) => {
                      if (e.target.value) {
                        field.Operation = e.target.value;
                        recalculateTotal();
                      } else {
                        field.Operation = "";
                        field.Label = "";
                        field.Amount = 0;
                        recalculateTotal();
                      }
                    }}
                  >
                    <option value=""></option>
                    <option value="+">+ Add</option>
                    <option value="-">- Subtract</option>
                    <option value="*">* Multiply</option>
                    <option value="/">/ Divide</option>
                  </select>
                </div>
              )}
            </td>
            <td>
              <div>
                <input
                  disabled={!field.Operation}
                  value={field.Label}
                  onChange={(e) => {
                    field.Label = e.target.value;
                    recalculateTotal();
                  }}
                  style={{ width: 150 }}
                />
              </div>
            </td>
            <td>
              <div>
                $
                <input
                  disabled={!field.Operation}
                  value={field.Amount}
                  onChange={(e) => {
                    let val = e.target.value;
                    val = val.replace(/[^0-9\.]+/g, "");
                    field.Amount = val as any;
                    recalculateTotal();
                  }}
                  style={{ width: 100 }}
                />
              </div>
            </td>
          </tr>
        ))}
        <tr>
          <th></th>
          <th>Total</th>
          <th>
            <div>{CommonLibUtils.prettyPrice(state.total)}</div>
          </th>
        </tr>
        </tbody>
      </table>
      <div
        className="flex-v-center"
        style={{
          justifyContent: "space-between",
          paddingTop: 10,
        }}
      >
        <div>
          {state.fields.length < 10 &&
            <Button variant="outlined" onClick={AddNewRow} startIcon={
              <RIcon name="add"/>
            }>
              New Row
            </Button>}
        </div>
        <div className="flex space-x-2">
          <Button variant="outlined" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            disabled={state.total < 0}
            onClick={() => {
              props.onComplete?.(
                {
                  ...props.objAdjustment,
                  BackgroundColor: "#C9B2FF",
                  SourceData: {
                    Fields: state.fields,
                    TotalAmount: state.total,
                  },
                },
                state.total,
              );
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

function CreateInitialRows(fields: IWorksheetAdjustmentSourceDataField[]) {
  let missingRows = 5 - fields.length;
  if (missingRows > 0) {
    for (let i = 0; i < missingRows; i++) {
      fields.push({
        Label: "",
        Amount: 0,
        Operation: i === 0 ? "+" : "",
      });
    }
  }
  return fields;
}
