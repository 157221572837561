import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorPlaceHolder from "common/ErrorPlaceHolder";
import ModalTitle from "common/modal/ModalTitle";
import { ITourismRevenueObject, smartYup, TAX_FILING_STATUS } from "commonlib";
import ValidationErrorList from "components/inputs/hookInputs/ValidationErrorList";
import withData from "hoc/withData";
import { IModalComponentProps } from "hooks/useModal";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { bSdk } from "services/BackendSDKService";
import * as yup from "yup";
import TaxFilingModalTitle from "../../tax-filings/TaxFilingModalTitle";
import TourismTaxTable from "./TourismTaxTable";

export default withData(TourismTaxRevenueModalV2, async (props) => {
  if (typeof props.contextObject === "string") {
    const result = await bSdk.TaxFilings_GetTourismTaxRevenue({
      TaxFilingID: props.contextObject,
    });
    return result.data;
  }
});

const schema = yup
  .object({
    TOURISM_TRANSIENT_RENTALS_GROSS_REVENUE: smartYup.numberRequired(),
    TOURISM_TRANSIENT_RENTALS_EXEMPT_REVENUE: smartYup.numberRequired(),
    TOURISM_FOOD_AND_BEVERAGE_GROSS_REVENUE: smartYup.numberRequired(),
    TOURISM_FOOD_AND_BEVERAGE_EXEMPT_REVENUE: smartYup.numberRequired(),
  })
  .test("Gross Revenue should not be less then exempt", "", function (value) {
    if (
      (value.TOURISM_FOOD_AND_BEVERAGE_EXEMPT_REVENUE || 0) >
      (value.TOURISM_FOOD_AND_BEVERAGE_GROSS_REVENUE || 0)
    )
      return false;
    if (
      (value.TOURISM_TRANSIENT_RENTALS_EXEMPT_REVENUE || 0) >
      (value.TOURISM_TRANSIENT_RENTALS_GROSS_REVENUE || 0)
    )
      return false;
    return true;
  });

export interface ITourismTaxRevenueData {
  Revenue: ITourismRevenueObject;
  IsEditable: boolean;
  Status: string;
  StateName: string;
  SalesRevenue: {
    TRANSIENT_RENTALS_GROSS_REVENUE?: number;
    TRANSIENT_RENTALS_EXEMPT_REVENUE?: number;
    FOOD_AND_BEVERAGE_GROSS_REVENUE?: number;
    FOOD_AND_BEVERAGE_EXEMPT_REVENUE?: number;
  };
  Month: string;
}

export interface ITourismTaxPreciseRevenue {
  TOURISM_TRANSIENT_RENTALS_GROSS_REVENUE?: number;
  TOURISM_TRANSIENT_RENTALS_EXEMPT_REVENUE?: number;
  TOURISM_FOOD_AND_BEVERAGE_GROSS_REVENUE?: number;
  TOURISM_FOOD_AND_BEVERAGE_EXEMPT_REVENUE?: number;
  TOURISM_TRANSIENT_RENTALS_TAX_COLLECTED?: number;
  TOURISM_FOOD_AND_BEVERAGE_TAX_COLLECTED?: number;
}

interface ITourismTaxRevenueModalV2 extends IModalComponentProps {
  data: ITourismTaxRevenueData;
  isPreview?: boolean;
}

function TourismTaxRevenueModalV2(props: ITourismTaxRevenueModalV2) {
  const [isEditable, setIsEditable] = useState(props.data?.IsEditable);

  const tourismTaxCalculation = useQuery<ITourismRevenueObject>(
    ["tourism-tax-calculation", props.contextObject],
    async () => {
      const result = await bSdk.TaxFilings_CalculateTourismTaxRevenue({
        Revenue: {
          ...props?.data.Revenue,
          ...objForm.getValues(),
        },
      });

      return result.data;
    },
    { retry: false, cacheTime: 0 } // removed caching for this query
  );

  const objForm = useForm<ITourismTaxPreciseRevenue>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      TOURISM_FOOD_AND_BEVERAGE_GROSS_REVENUE:
        props.data?.Revenue.TOURISM_FOOD_AND_BEVERAGE_GROSS_REVENUE || 0,
      TOURISM_FOOD_AND_BEVERAGE_EXEMPT_REVENUE:
        props.data?.Revenue.TOURISM_FOOD_AND_BEVERAGE_EXEMPT_REVENUE || 0,
      TOURISM_TRANSIENT_RENTALS_GROSS_REVENUE:
        props.data?.Revenue.TOURISM_TRANSIENT_RENTALS_GROSS_REVENUE || 0,
      TOURISM_TRANSIENT_RENTALS_EXEMPT_REVENUE:
        props.data?.Revenue.TOURISM_TRANSIENT_RENTALS_EXEMPT_REVENUE || 0,
      TOURISM_TRANSIENT_RENTALS_TAX_COLLECTED:
        props.data?.Revenue.TOURISM_TRANSIENT_RENTALS_TAX_COLLECTED || 0,
      TOURISM_FOOD_AND_BEVERAGE_TAX_COLLECTED:
        props.data?.Revenue.TOURISM_FOOD_AND_BEVERAGE_TAX_COLLECTED || 0,
    },
  });

  const action = useMutation(
    "updateTourismTaxRevenue",
    async (data: ITourismTaxPreciseRevenue) => {
      await bSdk.TaxFilings_UpdateTourismTaxRevenue({
        TaxFilingID: props.contextObject,
        ...data,
      });
    }
  );

  const approveTaxFiling = useMutation("approveTourismBatch", async () => {
    await bSdk.TaxFilings_ApproveTourismBatch({
      lstFilingIDs: [props.contextObject],
    });
  });

  const onSubmit = async (data: ITourismTaxPreciseRevenue) => {
    await action.mutateAsync(data);
    props.onComplete();
  };

  function onTextFieldBlur() {
    tourismTaxCalculation.refetch();
  }

  if (!props.data) return <div></div>;

  return (
    <div style={{ overflowX: "hidden" }}>
      <FormProvider {...objForm}>
        <ModalTitle
          title={
            <TaxFilingModalTitle
              title="(FL) TOURISM TAX CALCULATION WORKSHEET"
              objFiling={props.data as any}
            />
          }
          onBack={props.toggle}
          noSaveButton
        />
        <form
          onSubmit={objForm.handleSubmit(onSubmit)}
          id="DetailTaxFilingForm"
        >
          <Grid container>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "11px",
                }}
              >
                {props.data?.Status === TAX_FILING_STATUS.AWAITING_APPROVAL &&
                  props.isPreview && (
                    <LoadingButton
                      loading={approveTaxFiling.isLoading}
                      sx={{
                        fontWeight: "bold",
                        backgroundColor: "brown",
                        alignSelf: "flex-end",
                      }}
                      onClick={async () => {
                        await approveTaxFiling.mutateAsync();
                        props.onComplete();
                      }}
                    >
                      Approve
                    </LoadingButton>
                  )}
                {!isEditable && (
                  <Button
                    onClick={() => {
                      setIsEditable(true);
                    }}
                    sx={{
                      fontWeight: "bold",
                    }}
                    variant="outlined"
                    disabled={props.data?.Status === TAX_FILING_STATUS.SUCCESS}
                  >
                    Unlock
                  </Button>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              {tourismTaxCalculation.data && (
                <>
                  <TourismTaxTable
                    month={props.data.Month}
                    salesRevenue={props.data.SalesRevenue}
                    calculatedTourismTaxRevenue={tourismTaxCalculation.data}
                    onTextFieldBlur={onTextFieldBlur}
                    isEditable={isEditable}
                    objTaxFiling={props.data as any}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <ValidationErrorList />
            </Grid>
            {isEditable && (
              <Grid item xs={12}>
                <div>
                  {tourismTaxCalculation.isLoading && <CircularProgress />}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    columnGap: 8,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      objForm.setValue(
                        "TOURISM_FOOD_AND_BEVERAGE_GROSS_REVENUE",
                        0
                      );
                      objForm.setValue(
                        "TOURISM_FOOD_AND_BEVERAGE_EXEMPT_REVENUE",
                        0
                      );
                      objForm.setValue(
                        "TOURISM_TRANSIENT_RENTALS_GROSS_REVENUE",
                        0
                      );
                      objForm.setValue(
                        "TOURISM_TRANSIENT_RENTALS_EXEMPT_REVENUE",
                        0
                      );
                      objForm.setValue(
                        "TOURISM_TRANSIENT_RENTALS_TAX_COLLECTED",
                        0
                      );
                      objForm.setValue(
                        "TOURISM_FOOD_AND_BEVERAGE_TAX_COLLECTED",
                        0
                      );
                      onTextFieldBlur();
                    }}
                  >
                    Clear
                  </Button>
                  <LoadingButton type="submit" loading={action.isLoading}>
                    Save
                  </LoadingButton>
                </div>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
      {action.isError && <ErrorPlaceHolder ex={action.error} />}
      {tourismTaxCalculation.isError && (
        <ErrorPlaceHolder ex={tourismTaxCalculation.error} />
      )}
      {approveTaxFiling.isError && (
        <ErrorPlaceHolder ex={approveTaxFiling.error} />
      )}
    </div>
  );
}
