import { Tooltip } from "@mui/material";
import HTextField from "components/inputs/hookInputs/HTextField";
import React, { ComponentProps } from "react";
import { ITaxFilings } from "types/tax-filings";
import WorksheetSourceDataIcon from "./WorksheetSourceDataIcon";
import { useFormContext } from "react-hook-form";
import { ISalesRevenueWorksheetForm } from "./SalesRevenueWorksheet";
import { CommonLibUtils, IWorksheetAdjustmentObject } from "commonlib";

type IWorksheetTextFieldProps = ComponentProps<typeof HTextField> & {
  objTaxFiling: ITaxFilings;
  adjustmentKey?: string;
  enableSourceData?: boolean;
};

/**
 * WorksheetTextField and AdjustmentWrapper are both made for same purpose (To show adjustment in tooltip)
 * But, Both are required as WorksheetTextField will be used for TextField and For Label/Value AdjustmentWrapper will be used
 */
export default function WorksheetTextField(props: IWorksheetTextFieldProps) {
  const { objTaxFiling, ...other } = props;
  let objForm = useFormContext<ISalesRevenueWorksheetForm>();
  let objAdj: IWorksheetAdjustmentObject =
    props.objTaxFiling?.WorksheetAdjustment?.[props.adjustmentKey || "none"];

  let isDisabled = props?.componentProps?.disabled;

  let message = objAdj?.Message ?
    `<div style="padding: 5px 0 0 0;">
      <div style="padding: 5px 10px; margin-bottom: 5px; font-weight: 700; font-size: 15px; background-color: #b9b3b3; border: 2px solid white; color: black; width: 100%; text-align: center;">
        Data Extracted From Report
      </div> 
      <div>
        ${objAdj?.Message}
      </div>
    </div>` : "";

  if (objAdj?.SourceData?.Fields?.length) {
    const isDataExtracted = objAdj?.BackgroundColor === "lightblue";
    if (objAdj?.Message) {
      message += `
      <hr style="border-color: black !important; background-color: black !important;"/>
      <hr style="border-color: black !important; background-color: black !important;"/>`;
    }

    message += `<table class="bordered-table">
      <tbody>
        <tr>
            <th colspan="3">${isDataExtracted ? "Directly Extracted" : "Manual Entry"} Data</th>
        </tr>
        <tr>
          <th>Operation</th><th>Description</th><th>Amount</th>
        </tr>
        ${objAdj.SourceData.Fields.map((field) => {
      if (!field.Amount) return "";
      return `<tr><td>${field.Operation}</td><td>${
        field.Label
      }</td><td>${CommonLibUtils.prettyPrice(Number(field.Amount))}</td></tr>`;
    }).join("")}
        <tr class="footer">
          <td></td>
          <td>Total</td>
          <td>
            ${CommonLibUtils.prettyPrice(
              objAdj.SourceData.TotalAmount,
            )}
          </td>
        </tr>
      </tbody>
    </table>`;
  }

  return (
    <div className="flex-v-center">
      <Tooltip
        title={
          message && <div dangerouslySetInnerHTML={{ __html: message || "" }} />
        }
        disableFocusListener={false}
        disableInteractive={false}
      >
        <span>
          <HTextField
            {...other}
            componentProps={{
              ...props.componentProps,
              style: {
                backgroundColor: objAdj?.BackgroundColor,
                ...props.componentProps.style,
              },
            }}
          />
        </span>
      </Tooltip>
      {props.enableSourceData && !isDisabled && (
        <WorksheetSourceDataIcon
          objAdjustment={objAdj}
          onComplete={(objAdjustment, total) => {
            objForm.setValue(
              `SourceDataAdjustment.${props.adjustmentKey}`,
              objAdjustment,
            );
            objForm.setValue(props?.name as any, total);

            console.log(objAdjustment);
          }}
        />
      )}
    </div>
  );
}
