import { InputAdornment, Tooltip } from "@mui/material";
import RIcon from "common/misc/RIcon";
import {
  getTourismTaxComparisonSummary,
  ITourismRevenueObject,
  PERCENTAGE_VALUE_TYPE,
} from "commonlib";
import HTextField from "components/inputs/hookInputs/HTextField";
import React from "react";
import Utils from "services/Utils";
import { ITaxFilings } from "types/tax-filings";
import { prettyPercentage } from "utils/priceUtils";

interface ITourismTaxComparisonLineProps {
  objTaxFiling: ITaxFilings;
  Type: "TRANSIENT_RENTAL" | "FOOD_AND_BEVERAGE" | "BOTH";
}
export default function TourismTaxComparisonLine(
  props: ITourismTaxComparisonLineProps
) {
  let objRevenue: ITourismRevenueObject =
    (props.objTaxFiling?.Revenue as any) || {};

  let TaxCollected = 0;
  let TaxDue = 0;
  if (props.Type === "FOOD_AND_BEVERAGE" || props.Type === "BOTH") {
    TaxCollected += objRevenue.TOURISM_FOOD_AND_BEVERAGE_TAX_COLLECTED || 0;
    TaxDue += objRevenue._TOURISM_FOOD_AND_BEVERAGE_DUE || 0;
  }
  if (props.Type === "TRANSIENT_RENTAL" || props.Type === "BOTH") {
    TaxCollected += objRevenue.TOURISM_TRANSIENT_RENTALS_TAX_COLLECTED || 0;
    TaxDue += objRevenue._TOURISM_TRANSIENT_RENTALS_DUE || 0;
  }
  let difference = getTourismTaxComparisonSummary({
    TaxVarianceThresholdType:
      props.objTaxFiling?.SubClient?.TaxVarianceThresholdType,
    TaxVarianceThresholdValue:
      props.objTaxFiling?.SubClient?.TaxVarianceThresholdValue,
    TaxCollected: TaxCollected,
    TaxDue: TaxDue,
  });

  let txtThreshold = "";
  if (
    props.objTaxFiling?.SubClient?.TaxVarianceThresholdType ===
    PERCENTAGE_VALUE_TYPE.PERCENTAGE
  ) {
    txtThreshold = prettyPercentage(
      props.objTaxFiling?.SubClient?.TaxVarianceThresholdValue
    );
  } else if (
    props.objTaxFiling?.SubClient?.TaxVarianceThresholdType ===
    PERCENTAGE_VALUE_TYPE.AMOUNT
  ) {
    txtThreshold = Utils.prettyPrice(
      props.objTaxFiling?.SubClient?.TaxVarianceThresholdValue
    );
  }

  if (!props.objTaxFiling?.Revenue?._TOURISM_TOTAL_TAX_DUE) return null;
  return (
    <div
      style={{
        color: difference.result.color,
        fontWeight: "bold",
        whiteSpace: "nowrap",
      }}
      className="flex-v-center"
    >
      {difference.result.label}{" "}
      {!!difference.differenceBy && (
        <span>By {Utils.prettyPrice(Math.abs(difference.differenceBy))}</span>
      )}
      {difference.ShowTick === true && (
        <Tooltip
          title={`The difference ${Utils.prettyPrice(
            Math.abs(difference.differenceBy)
          )} is within Threshold ${txtThreshold}. `}
        >
          <RIcon name={"check_circle"} color={"success"} />
        </Tooltip>
      )}
      {difference.ShowTick === false && (
        <Tooltip
          title={`The difference ${Utils.prettyPrice(
            Math.abs(difference.differenceBy)
          )} hax exceeded the Threshold ${txtThreshold}. `}
        >
          <RIcon name={"visibility"} color={"error"} />
        </Tooltip>
      )}
    </div>
  );
}
