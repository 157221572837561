import { showDialog } from "common/modal/ConfirmModal";
import { ISummaryItem } from "commonlib";
import { subMonths } from "date-fns";
import { AlertUtils } from "hyper-utils";
import { Store } from "pullstate";
import { bSdk } from "services/BackendSDKService";
import Utils from "services/Utils";
import { ITaxFilings } from "types/tax-filings";
import { withLoadingError } from "utils/loadingUtils";
import TourismTaxRevenueModalV2 from "./tourism-tax-revenue-v2/TourismTaxRevenueModalV2";
import { ITourismTaxFilingFilterState } from "./TourismTaxFilingPageNew/TourismTaxFilingFilterModal";

interface ITourismTaxStore {
  SelectedMonth: Date;
  SelectedTaxFilingStatus: ISummaryItem;
  isLastMonthRecordExists?: boolean;
  AdditionalFilters: ITourismTaxFilingFilterState;

  SelectedRows: {
    [TaxFilingID: string]: ITaxFilings | false;
  };
  ExpandedRows: {
    [TaxFilingID: string]: boolean;
  };
  SelectedAll?: boolean;
  ExpandedAll?: boolean;
}

export const TourismTaxStore = new Store<ITourismTaxStore>({
  SelectedMonth: subMonths(new Date(), 1),

  SelectedTaxFilingStatus: {
    key: "ALL",
  } as any,
  SelectedRows: {},
  ExpandedRows: {},
  AdditionalFilters: {},
});

export const fnStartTourismTaxFiling = withLoadingError(
  async (row: ITaxFilings) => {
    try {
      await bSdk.cmsQuery(
        "tax-filings",
        {
          SubClientID: row.SubClientID,
          TaxFilingID: row._id,
        },
        { route: "/startTourismTaxFiling", method: "post" }
      );
    } catch (ex) {
      AlertUtils.showError(ex);
    }
  }
);

export function reloadTourismTaxPage() {
  Utils.fireEvent("TOURISM_TAX_PAGE_RELOAD", { data: true });
}

export function showTourismTaxWorksheetModal(row: ITaxFilings) {
  let localProps = {
    onComplete: () => {
      modal.toggle(false);
      reloadTourismTaxPage();
    },
    toggle: () => {
      modal.toggle(false);
    },
    contextObject: row._id,
  };
  let modal = showDialog({
    body: (
      <TourismTaxRevenueModalV2
        onComplete={() => {
          modal.toggle(false);
          reloadTourismTaxPage();
        }}
        toggle={() => modal.toggle(false)}
        contextObject={row._id}
      />
    ),
    dialogProps: {
      maxWidth: "lg",
    },
  });
}
