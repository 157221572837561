import { Box, Stack, Theme, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { IStateFilingDates_GetLastDatesReturnType } from "backendsdk";
import RenderEnum from "common/RenderEnum/RenderEnum";
import { useQuery } from "react-query";
import { bSdk } from "services/BackendSDKService";
import Utils from "services/Utils";
import { ITaxFilings } from "types/tax-filings";
// import TourismTaxRevenueModalV2 from "./tourism-tax-revenue-v2/TourismTaxRevenueModalV2";

interface ITaxFilingModalTitleProps {
  title: string;
  objFiling?: ITaxFilings;
}

export default function TaxFilingModalTitle(props: ITaxFilingModalTitleProps) {
  const theme = useTheme<Theme>();
  const lastDate = useQuery<IStateFilingDates_GetLastDatesReturnType | null>(
    [props.objFiling?.State.StateID, props.objFiling?.Month],
    async () => {
      if (!props.objFiling) return null;
      let result = await bSdk.StateFilingDates_GetLastDates({
        Month: props.objFiling.Month,
        StateID: props.objFiling.State.StateID,
      });
      return result.data;
    }
  );

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography sx={{ fontSize: 17, fontWeight: "bold", color: "red" }}>
          {props.title}
          <div style={{ display: "flex", width: "100%" }}>
            <Typography sx={{ fontSize: 15, fontWeight: "bold", pb: 0, mb: 0 }}>
              <Stack
                direction="row"
                alignItems="center"
                spacing="10px"
                width="100%"
              >
                {props.objFiling?.SubClient?.CorporationName && (
                  <span style={{ color: theme.palette.primary.main }}>
                    {props.objFiling.SubClient.CorporationName}
                  </span>
                )}
                {props.objFiling?.SubClient?.DBA && (
                  <span style={{ color: theme.palette.secondary.dark }}>
                    {props.objFiling.SubClient.DBA}
                  </span>
                )}

                {props.objFiling?.SubClient?.NickName && (
                  <span style={{ color: theme.palette.info.dark }}>
                    {props.objFiling.SubClient.NickName}
                  </span>
                )}
                {props.objFiling?.SubClient?.BusinessType && (
                  <RenderEnum
                    code="SUB_CLIENT_BUSINESS_TYPE"
                    value={props.objFiling.SubClient.BusinessType}
                  />
                )}
                {props.objFiling?.SubClient?.County && (
                  <span style={{ color: theme.palette.warning.dark }}>
                    {props.objFiling.SubClient.County}
                  </span>
                )}
              </Stack>
            </Typography>
          </div>
        </Typography>

        <Box>
          {lastDate.data?.LastDateOfFiling && (
            <Typography sx={{ fontSize: 17, fontWeight: "bold", mr: 1 }}>
              Last Date Of Filing:{" "}
              {Utils.convertToLastDateFormat(lastDate.data.LastDateOfFiling)}
            </Typography>
          )}

          {lastDate.data?.LastDateOfPayment && (
            <Typography sx={{ fontSize: 17, fontWeight: "bold", mr: 1 }}>
              Payment Deduction:{" "}
              {Utils.convertToLastDateFormat(lastDate.data.LastDateOfPayment)}
            </Typography>
          )}
        </Box>
      </div>
    </div>
  );
}
